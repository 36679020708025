import { useQuery } from "@tanstack/react-query";
import { apiUrls } from "../../apiUrls";
import { request } from "../../../services/axios.service";
import { store } from "../../../redux-app/store";
import { updateAppSettings } from "../../../redux-app/reducer/settings/settings-reducer";

const get = async () => {
  const response: TServerResponse = await request({
    url: apiUrls.settings.FETCH_SETTINGS,
    method: "GET",
  });

  store.dispatch(updateAppSettings(response.data));
  return response;
};

export const useSettingsQuery = (enabled: boolean) => {
  return useQuery({
    queryKey: [apiUrls.settings.FETCH_SETTINGS],
    queryFn: get,
    enabled: enabled,
  });
};
