import BackBtnHeader from "../components/backBtn-header";
import Container from "../container";
import PaymentSuccess from "../components/checkout/payment-success/PaymentSuccess";
import PaymentFailed from "../components/checkout/payment-failed/PaymentFailed";
import PaymentWaiting from "../components/checkout/payment-verification/PaymentWaiting";
import { useEffect, useMemo } from "react";
import { useVerifyPaymentStatusQuery } from "../hooks/payments/query/useVerifyPaymentStatus.query";
import { useAppSelector } from "../redux-app/hooks";
import { useWebsocket } from "../services/socket.service";

const PaymentStatus = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const trackId = searchParams.get("trackId");
  const { isLoading, data, refetch } = useVerifyPaymentStatusQuery(
    trackId ?? ""
  );
  const { socket } = useWebsocket();

  const paymentInfo: TPayments = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return undefined;
    }
  }, [isLoading, data]);

  const renderStatus = () => {
    switch (paymentInfo?.status) {
      case "Waiting":
        return (
          <PaymentWaiting
            title="USDT deposit initiated"
            message="Your deposit is being processed. The USDT will be transferred to your wallet within 1-2 minutes after network confirmation.Thank you for your patience."
          />
        );
      case "Paid":
        return (
          <PaymentSuccess
            title="USDT Deposit Success"
            message="Your deposit has been successfully processed, and the USDT has been transferred to your wallet. Thank you for using Discounted Alts!"
          />
        );
      case "Failed":
        return (
          <PaymentFailed
            title="USDT Deposit failed"
            message="Unfortunately, your deposit could not be processed as the transfer to the specified crypto address was not completed within the required time frame. As a result, the deposit has expired. Kindly initiate the deposit again to proceed. Thank you for your understanding."
          />
        );
      default:
        return (
          <PaymentWaiting
            title="USDT deposit initiated"
            message="Your deposit is being processed. The USDT will be transferred to your wallet within 1-2 minutes after network confirmation.Thank you for your patience."
          />
        );
    }
  };
  const { _id } = useAppSelector((state) => state.user.userData);
  useEffect(() => {
    if (socket) {
      socket.on("payment-status-updated", ({ userId, status }) => {
        console.log("userId", userId);
        console.log("status", status);

        if (_id === userId) {
          refetch();
        }
      });
    }
    return () => {
      socket?.off("payment-status-updated");
    };
  }, [socket, refetch, _id]);

  return (
    <Container loading={isLoading}>
      <BackBtnHeader heading={"Payment Status"} />
      {renderStatus()}
    </Container>
  );
};

export default PaymentStatus;
