import { Fragment, memo, useState } from "react";
import classes from "./index.module.scss";
import PrivacyPolicyModel from "../../models/privacy-policy";
import TermCondition from "../../models/term-condition/TermCondition";

const PrivacyPolicyTag = () => {
  const [modal, setModal] = useState({
    termAndCondition: false,
    privacyModal: false,
  });

  return (
    <Fragment>
      <p className={classes.infoText}>
        By creating an account, you’re agree to out
        <button
          onClick={() =>
            setModal((state) => ({ ...state, privacyModal: true }))
          }
        >
          Privacy policy
        </button>
        and
        <button
          onClick={() =>
            setModal((state) => ({ ...state, termAndCondition: true }))
          }
        >
          Term & conditions
        </button>
      </p>
      <PrivacyPolicyModel
        show={modal.privacyModal}
        handelClose={() =>
          setModal({ privacyModal: false, termAndCondition: false })
        }
      />
      <TermCondition
        show={modal.termAndCondition}
        handelClose={() =>
          setModal({ privacyModal: false, termAndCondition: false })
        }
      />
    </Fragment>
  );
};

export default memo(PrivacyPolicyTag);
