import { memo } from "react";
import Container from "../../container";
import classes from "./index.module.scss";
import { IMAGES } from "../../assets/images";
import { Link } from "react-router-dom";
import PrivacyPolicyTag from "./privacy-policy-tag";
import ROUTES from "../../enum/routes";

const BoardingTwoScreen = () => {
  return (
    <Container enableBg={false}>
      <div className={classes.container}>
        <div className={`${classes.banner_boarding} ${classes.mt_30}`}>
          <img src={IMAGES.boarding1} alt="img" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="375"
            height="335"
            viewBox="0 0 381 335"
            fill="none"
          >
            <g opacity="0.8" filter="url(#filter0_f_1_12068)">
              <path
                d="M269.131 167.427C182.034 233.37 239.606 233.37 203.186 233.37C166.765 233.37 237.195 246.642 137.241 167.427C37.2862 88.2118 166.765 101.483 203.186 101.483C239.606 101.483 356.228 101.483 269.131 167.427Z"
                fill="url(#paint0_linear_1_12068)"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_1_12068"
                x="0.106079"
                y="0.647461"
                width="400.637"
                height="333.559"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="50"
                  result="effect1_foregroundBlur_1_12068"
                />
              </filter>
              <linearGradient
                id="paint0_linear_1_12068"
                x1="275.664"
                y1="100.647"
                x2="193.485"
                y2="267.1"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#E250E5" />
                <stop offset="1" stopColor="#4B50E6" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className={classes.content}>
          <h2 className={classes.heading}>Secure. Reliable. Fast</h2>
          <p className={`${classes.medium_text} ${classes.mt_8}`}>
            Join a platform trusted by thousands, processing over 1,500,000+
            transactions daily. Your go-to solution for efficient and reliable
            cryptocurrency purchases.
          </p>
          <Link to={ROUTES.SIGN_UP} className={classes.nextBtn}>
            Get Started{" "}
          </Link>
          <PrivacyPolicyTag />
        </div>
      </div>
    </Container>
  );
};

export default memo(BoardingTwoScreen);
