import { authUrls } from "./auth.url";
import { buyTokenUrls } from "./buyToken.url";
import { currencyApis } from "./currency.api";
import { depositUrls } from "./deposit.urls.api";
import { invoiceUrls } from "./invoice.url";
import { payoutUrls } from "./payout";
import { settingsApis } from "./settings.api";

export const apiUrls = {
  auth: authUrls,
  currency: currencyApis,
  settings: settingsApis,
  deposit: depositUrls,
  buyToken: buyTokenUrls,
  invoice: invoiceUrls,
  payout: payoutUrls,
};
