import React from "react";
import classes from "./index.module.scss";
import Button from "../../../ui/button";
import { Modal } from "@mantine/core";

interface IProps {
  show: boolean;
  handelClose: () => void;
}

const PrivacyPolicyModel: React.FC<IProps> = ({ show, handelClose }) => {
  return (
    <Modal
      centered
      size={335}
      withCloseButton={false}
      opened={show}
      onClose={handelClose}
      classNames={{
        content: classes.modal_content,
      }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      transitionProps={{
        transition: "fade-down",
        duration: 200,
        timingFunction: "linear",
      }}
      radius={8}
    >
      <div>
        <h3 className={classes.heading}>Privacy Policy</h3>
        <div className={classes.text_small}>
          <ol className={classes.orderList}>
            <li>
              <p>
                The platform generates a unique private key for each user during
                account creation, which is solely the user's responsibility to
                safeguard.
              </p>
            </li>
            <li>
              <p>
                The platform does not store, access, or recover private keys. If
                lost, the user may lose access to their account permanently.
              </p>
            </li>
            <li>
              <p>
                Personal information collected during transactions, such as
                wallet addresses, is used strictly for processing and is not
                shared with unauthorized third parties.
              </p>
            </li>
            <li>
              <p>
                Transactions are processed automatically. Any discrepancies,
                such as incorrect deposit amounts, are irreversible, and the
                platform is not liable for such errors.
              </p>
            </li>
            <li>
              <p>
                All transaction data is recorded publicly and transparently on
                the blockchain for verification.
              </p>
            </li>
            <li>
              <p>
                The platform uses advanced encryption technologies to protect
                user data and transaction details during processing.
              </p>
            </li>
            <li>
              <p>
                Network fees for all transactions are covered by the platform,
                ensuring a seamless user experience.
              </p>
            </li>
            <li>
              <p>
                Users are encouraged to double-check all transaction details,
                including wallet addresses and amounts, as incorrect information
                may result in irreversible loss of funds.
              </p>
            </li>
            <li>
              <p>
                The platform may collect anonymized data for analytics to
                improve services and enhance the user experience.
              </p>
            </li>
            <li>
              <p>
                The platform complies with applicable data protection and
                privacy laws to ensure the confidentiality and security of user
                data.
              </p>
            </li>
            <li>
              <p>
                By using the platform, users agree to the terms outlined in this
                Privacy Policy and the associated Terms and Conditions.
              </p>
            </li>
          </ol>
        </div>
        {/* <div className={classes.privacyContainer}>
          <div className={classes.form_group}>
            <input type="checkbox" id="html" />
            <label style={{ marginLeft: 10 }} htmlFor="html">
              I agree to the Privacy policy
            </label>
          </div>
        </div> */}
        <div className={classes.acceptBtnContainer}>
          <Button title="I Accept" type={"button"} onClick={handelClose} />
        </div>
      </div>
    </Modal>
  );
};

export default PrivacyPolicyModel;
