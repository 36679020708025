import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TCurrencyData = {
  _id: string;
  code: "BTC" | "ETH" | "BNB" | "SOL" | "USDT" | "TRX" | "TONCOIN";
  rate: number;
  volume: number;
  cap: number;
  delta: {
    hour: number;
    day: number;
    week: number;
    month: number;
    quarter: number;
    year: number;
  };
  offerPrice: number;
  offerPercent: number;
  fluctuatedPercent: number;
};

interface IGraphData {
  [key: string]: number[];
}

interface ICurrencyState {
  currencies: TCurrencyData[];
  graphData: IGraphData;
}

const initialState: ICurrencyState = {
  currencies: [],
  graphData: {},
};

export const currencyRatesSlice = createSlice({
  name: "currency-rates",
  initialState,
  reducers: {
    updateLatestRates: (state, action: PayloadAction<TCurrencyData[]>) => {
      const allowedCodes = ["BTC", "ETH", "BNB", "SOL", "TONCOIN", "TRX"];
      state.currencies = action.payload
        .filter((currency) => allowedCodes.includes(currency.code))
        .sort(
          (a, b) => allowedCodes.indexOf(a.code) - allowedCodes.indexOf(b.code)
        );
    },

    updateLatestGraph: (state, action: PayloadAction<IGraphData>) => {
      state.graphData = action.payload;
    },

    fluctuateOfferPrices: (state) => {
      state.currencies = state.currencies.map((currency) => {
        // Calculate fluctuation range for offerPercent (±30%)
        const percentFluctuationRange = currency.offerPercent * 0.3;

        // Generate a random fluctuation within ±30% of the original offerPercent
        const randomPercentFluctuation =
          Math.random() * (2 * percentFluctuationRange) -
          percentFluctuationRange;

        // Calculate the new fluctuated percent
        const fluctuatedPercent = Math.max(
          0,
          Math.min(
            currency.offerPercent,
            currency.offerPercent + randomPercentFluctuation
          )
        );

        // Calculate new offerPrice based on fluctuatedPercent
        const newOfferPrice =
          currency.rate - (fluctuatedPercent / 100) * currency.rate;

        return {
          ...currency,
          fluctuatedPercent, // Store fluctuated percent
          offerPrice: newOfferPrice, // Update offerPrice based on fluctuatedPercent
        };
      });
    },
  },
});

export const { updateLatestRates, updateLatestGraph, fluctuateOfferPrices } =
  currencyRatesSlice.actions;

export default currencyRatesSlice.reducer;

// Function to start the fluctuation logic
export const startFluctuation = (dispatch: any) => {
  setInterval(() => {
    dispatch(fluctuateOfferPrices());
  }, 2000);
};
