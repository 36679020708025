import { memo, useEffect } from "react";
import { useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import TheLayout from "../container/TheLayout";
import { useWebsocket } from "../services/socket.service";

const ProtectedRoute = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useWebsocket();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/welcome", { replace: true });
    }
  }, [navigate, isAuthenticated]);

  return <TheLayout />;
};

export default memo(ProtectedRoute);
