import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../apiUrls";

const get = async (trackId: string) => {
  const response: TServerResponse = await request({
    url: apiUrls.payout.CHECK_PAYOUT_STATUS,
    method: "GET",
    params: {
      trackId,
    },
  });
  return response;
};

export const useCheckPayoutStatusQuery = (trackId: string) => {
  return useQuery({
    queryKey: [apiUrls.payout.CHECK_PAYOUT_STATUS],
    queryFn: () => get(trackId),
  });
};
