import React, { memo } from "react";
import classes from "./index.module.scss";
import Button from "../../../ui/button";
import { Modal } from "@mantine/core";

interface IProps {
  show: boolean;
  handelClose: () => void;
}

const TermCondition: React.FC<IProps> = ({ handelClose, show }) => {
  return (
    <Modal
      centered
      size={343}
      withCloseButton={false}
      opened={show}
      onClose={handelClose}
      classNames={{
        content: classes.modal_content,
      }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      transitionProps={{
        transition: "fade-down",
        duration: 200,
        timingFunction: "linear",
      }}
      radius={8}
    >
      <div className={classes.modal_content}>
        <div>
          <h3 className={classes.heading}>Terms and Conditions</h3>
          <div className={classes.text_small}>
            <ol className={classes.orderList}>
              <li>
                <p>
                  When a user creates an account, a randomly generated private
                  key is provided, which is the only way to log in to the
                  account.
                </p>
              </li>
              <li>
                <p>
                  If the private key is lost, the user may permanently lose
                  access to their account.
                </p>
              </li>
              <li>
                <p>
                  The platform supports the purchase of six cryptocurrencies:
                  BTC, ETH, SOL, BNB, TON coin, and TRX.
                </p>
              </li>
              <li>
                <p>
                  The platform's algorithm compares prices across 200+
                  well-known DeFi and centralized exchanges to fetch the lowest
                  prices.
                </p>
              </li>
              <li>
                <p>
                  Cryptocurrency prices are dynamic and may change within
                  seconds due to market fluctuations.
                </p>
              </li>
              <li>
                <p>
                  Deposits and withdrawals are automated to ensure smooth and
                  secure transactions.
                </p>
              </li>
              <li>
                <p>
                  Users can deposit USDT directly using various supported crypto
                  networks.
                </p>
              </li>

              <li>
                <p>The minimum deposit amount for USDT is 10 USDT.</p>
              </li>
              <li>
                <p>
                  There is no processing delay; transaction time depends solely
                  on network block confirmation, typically completed within 1-2
                  minutes.
                </p>
              </li>
              <li>
                <p>
                  If a user enters an incorrect deposit amount or creates an
                  invoice for a different amount than deposited, the
                  cryptocurrency will be lost due to direct processing.
                </p>
              </li>

              <li>
                <p>
                  The platform covers network fees for transactions, so users do
                  not need to worry about additional charges.
                </p>
              </li>
              <li>
                <p>
                  Users are advised to keep their private key secure and avoid
                  sharing it with anyone.
                </p>
              </li>
              <li>
                <p>
                  Wallet addresses entered during transactions must be
                  double-checked to avoid loss of funds.
                </p>
              </li>
              <li>
                <p>
                  The platform is not liable for any losses resulting from user
                  errors, negligence, or incorrect information.
                </p>
              </li>
              <li>
                <p>
                  Transactions are final and cannot be reversed once processed.
                </p>
              </li>
              <li>
                <p>
                  The platform reserves the right to update or modify terms and
                  conditions without prior notice.
                </p>
              </li>
              <li>
                <p>
                  Unauthorized use, access, or manipulation of the platform is
                  strictly prohibited and may result in account suspension.
                </p>
              </li>
              <li>
                <p>
                  Users are required to comply with the applicable laws and
                  regulations in their region.
                </p>
              </li>
              <li>
                <p>
                  Customer support is available for any assistance or issues.
                </p>
              </li>
            </ol>
          </div>
          {/* <div className={classes.privacyContainer}>
            <div className={classes.form_group}>
              <input type="checkbox" id="html-Term" />
              <label htmlFor="html-Term">I agree to the Term of service.</label>
            </div>
          </div> */}
          <div className={classes.acceptBtnContainer}>
            <Button title="I Accept" type={"button"} onClick={handelClose} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(TermCondition);
