import { memo, useEffect } from "react";
import HomeScreen from "../components/home/HomeScreen";
import { useAppDispatch } from "../redux-app/hooks";
import { startFluctuation } from "../redux-app/reducer/currency-rates/currency-rates-reducer";

const Home = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    startFluctuation(dispatch);
  }, [dispatch]);
  return <HomeScreen />;
};

export default memo(Home);
