import React, { memo } from "react";
import classes from "./index.module.scss";
import { Marquee } from "@gfazioli/mantine-marquee";
import { Flex, Image } from "@mantine/core";
import { clients } from "./data";

const Clients: React.FC = () => {
  return (
    <div className={classes.root}>
      <h5 className={classes.heading}>Fetching Best Price From</h5>
      <div className={classes.cardContainer}>
        <Marquee w={"100%"} h={100} duration={100}>
          {clients.map((client) => (
            <Flex
              key={client.name}
              direction={"column"}
              align={"center"}
              mx={20}
            >
              <Image src={client.icon} w={35} />
              <p className={classes.coinName}>{client.name}</p>
            </Flex>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default memo(Clients);
