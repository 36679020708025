import React, { memo } from "react";
import classes from "./index.module.scss";

import CoinRate from "../../coin-rate/CoinRate";
import { ActionIcon, Flex, Image } from "@mantine/core";
import { IMAGES } from "../../../assets/images";
import { IconInfoCircle } from "@tabler/icons-react";
import InformationModal from "../../../ui/modals/information-modal/InformationModal";
import { useAppSelector } from "../../../redux-app/hooks";
import { useDisclosure } from "@mantine/hooks";

const CryptoRating: React.FC = () => {
  const { appInformation } = useAppSelector((state) => state.settings.data);
  const [opened, { open, close }] = useDisclosure();
  return (
    <div className={classes.root}>
      <Flex justify={"space-between"} align={"center"} mb={20}>
        <h5 className={classes.heading}>
          <Image src={IMAGES.logo} style={{ width: 30 }} />
          <span> Discounted Tokens</span>
        </h5>

        <ActionIcon onClick={open} variant="subtle">
          <IconInfoCircle size={20} color="white" />
        </ActionIcon>
      </Flex>
      <CoinRate />
      <InformationModal
        handelClose={close}
        info={appInformation.discountedToken}
        show={opened}
      />
    </div>
  );
};

export default memo(CryptoRating);
