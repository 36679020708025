import { Button, Flex, Modal } from "@mantine/core";
import React, { FC } from "react";
import classes from "./styles.module.scss";
import { IconCheck, IconX } from "@tabler/icons-react";

const CustomAlertModal: FC<AlertModalProps> = ({
  alertData: {
    title,
    message,
    preventClose,
    type,
    okCallBack,
    okTitle,
    cancelTitle,
    cancelCallback,
  },
  setShowAlert,
  showAlert,
}) => {
  const handleClose = () => setShowAlert(false);

  return (
    <Modal
      centered
      size={350}
      withCloseButton={false}
      opened={showAlert}
      onClose={preventClose ? () => {} : handleClose}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      transitionProps={{
        transition: "fade-down",
        duration: 200,
        timingFunction: "linear",
      }}
      radius={8}
    >
      <div className={`${classes.success_box}`}>
        <div className={`${classes.icon_1} ${classes.ani3}`}>
          <span
            className={`${classes.circle_box} ${classes.lg} ${
              type === "error" && classes.error_circle_box
            }`}
          >
            {type === "error" ? (
              <IconX color="white" size={50} />
            ) : (
              <IconCheck color="white" size={50} />
            )}
          </span>
        </div>
        <div className={`${classes.icon_2} ${classes.ani5}`}>
          <span
            className={`${classes.circle_box} ${classes.md} ${
              type === "error" && classes.error_circle_box
            }`}
          ></span>
        </div>
        <div className={`${classes.icon_3} ${classes.ani8}`}>
          <span
            className={`${classes.circle_box} ${classes.md} ${
              type === "error" && classes.error_circle_box
            }`}
          ></span>
        </div>
        <div className={`${classes.icon_4} ${classes.ani2}`}>
          <span
            className={`${classes.circle_box} ${classes.sm} ${
              type === "error" && classes.error_circle_box
            }`}
          ></span>
        </div>
        <h2 className={`${classes.modalTitle}`}>{title}</h2>
        <p className={`${classes.modalDescription}`}>{message}</p>
        <Flex justify={"center"} mt={20}>
          {cancelTitle && (
            <Button
              classNames={{
                root: `${classes.cancelBtn} ${
                  type === "error" && classes.border_error
                }`,
              }}
              mx={5}
              size="compact-md"
              onClick={handleClose}
            >
              {cancelTitle ?? "Cancel"}
            </Button>
          )}

          <Button
            classNames={{
              root: `${classes.okBtn} ${
                type === "error" && classes.background_error
              }`,
            }}
            mx={5}
            size="compact-md"
            onClick={() => {
              okCallBack?.();
              handleClose();
            }}
          >
            {okTitle ?? "OK"}
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

export default CustomAlertModal;
