import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../apiUrls";

const get = async () => {
  const response: TServerResponse = await request({
    url: apiUrls.auth.FETCH_NOTIFICATIONS,
    method: "GET",
  });
  return response;
};

export const useNotificationsQuery = () => {
  return useQuery({
    queryKey: [apiUrls.auth.FETCH_NOTIFICATIONS],
    queryFn: () => get(),
  });
};
