import { Box, Flex, Text } from "@mantine/core";
import classes from "./PaymentSuccess.module.scss";
import { IconCheck } from "@tabler/icons-react";
import Button from "../../../ui/button";
import { useNavigate } from "react-router-dom";
import { FC } from "react";

interface IPaymentSuccess {
  title: string;
  message: string;
}

const PaymentSuccess: FC<IPaymentSuccess> = ({ message, title }) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Flex
        justify={"center"}
        align={"center"}
        direction={"column"}
        className={classes.root}
      >
        <div className={classes.success_box}>
          <div className={`${classes.icon_1} ${classes.ani3}`}>
            <span className={`${classes.circle_box} ${classes.lg}`}>
              <IconCheck color="white" size={50} />
            </span>
          </div>
          <div className={`${classes.icon_2} ${classes.ani5}`}>
            <span className={`${classes.circle_box} ${classes.md}`}></span>
          </div>
          <div className={`${classes.icon_3} ${classes.ani8}`}>
            <span className={`${classes.circle_box} ${classes.md}`}></span>
          </div>
          <div className={`${classes.icon_4} ${classes.ani2}`}>
            <span className={`${classes.circle_box} ${classes.sm}`}></span>
          </div>
        </div>

        <Text className={classes.title}>{title}</Text>
        <Text className={classes.notes}>{message}</Text>
        <Flex mt={30}>
          <Button
            title="Go to Home"
            type="button"
            onClick={() => navigate("/")}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default PaymentSuccess;
