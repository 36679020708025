import { buyTokenValidation } from "./buy-token/validation";
import { buyTokenIniValues } from "./buy-token/values";
import { depositValidation } from "./deposit/validation";
import { depositValues } from "./deposit/values";
import { loginValidation } from "./login/validation";
import { loginValues } from "./login/values";

export const forms = {
  login: {
    validation: loginValidation,
    values: loginValues,
  },
  deposit: {
    validation: depositValidation,
    values: depositValues,
  },
  buyToken: {
    values: buyTokenIniValues,
    validation: buyTokenValidation,
  },
};
