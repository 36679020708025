import React, { memo, useMemo } from "react";
import classes from "./index.module.scss";
import { Box, Flex } from "@mantine/core";
import { SVG } from "../../../assets/svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../enum/routes";
import { IconInfoCircle } from "@tabler/icons-react";

interface IHistoryCard {
  data: TPayments;
}

const HistoryCard: React.FC<IHistoryCard> = ({
  data: { amount, createdAt, status, invoiceId },
}) => {
  const navigate = useNavigate();
  const getColor = () => {
    switch (status) {
      case "Paid":
        return "#25c866";
      case "Failed":
        return "#d32f2f";
      case "New":
        return "#F7CB73";
      case "Expired":
        return "#d32f2f";
      case "Waiting":
        return "#F7CB73";
      case "Confirming":
        return "#F7CB73";
    }
  };

  const paymentStatus = useMemo(() => {
    switch (status) {
      case "Expired":
        return "Expired";
      case "Waiting":
        return "Waiting for payment";
      case "Failed":
        return "Rejected";
      case "New":
        return "New";
      case "Paid":
        return "Approved";
      case "Confirming":
        return "Confirming";
    }
  }, [status]);
  return (
    <Box
      className={classes.row}
      onClick={() => {
        (status === "New" || status === "Waiting") &&
          navigate(ROUTES.PAYMENT_CHECKOUT.replace(":invoiceId", invoiceId));
      }}
    >
      <div className={classes.svgIconContainer}>{SVG.usdt}</div>
      <Flex w={"100%"} justify={"space-between"} align={"center"}>
        <div className={classes.title}>
          <p style={{ margin: 0 }}> {"Deposit"} </p>
          <span> {moment(createdAt).format("lll")} </span>
        </div>
        <div className={classes.rate}>
          <p className={classes.coinRate}>
            <span className={classes.positive}>+</span>{" "}
            {amount.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}{" "}
            USDT
          </p>
          <Flex justify={"flex-end"} mt={-3} align={"center"}>
            <p style={{ color: getColor() }} className={classes.status}>
              {paymentStatus}
            </p>
            {(paymentStatus === "New" ||
              paymentStatus === "Waiting for payment") && (
              <IconInfoCircle
                color="white"
                size={12}
                style={{ marginTop: 5 }}
              />
            )}
          </Flex>
        </div>
      </Flex>
    </Box>
  );
};

export default memo(HistoryCard);
