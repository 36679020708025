import React from "react";
import classes from "./index.module.scss";
import { SVG } from "../../../../assets/svg";
import { useAppSelector } from "../../../../redux-app/hooks";

const Wallet = () => {
  const { usdtWallet } = useAppSelector((state) => state.user.userData);

  return (
    <div className={classes.root}>
      <div className={classes.walletCol}>{SVG.wallet}</div>
      <div>
        <h4 className={classes.heading}>USDT Balance</h4>
        <span className={classes.amount}>
          {usdtWallet.totalAmount.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })}
          {" USDT"}
        </span>
      </div>
    </div>
  );
};

export default Wallet;
