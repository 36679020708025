import React, { memo, ReactNode } from "react";
import classes from "./index.module.scss";
import { IMAGES } from "../assets/images";

interface IProps {
  children: ReactNode;
  loading?: boolean;
  enableBg?: boolean;
}

const Container: React.FC<IProps> = ({
  children,
  loading,
  enableBg = true,
}) => {
  return (
    <div className={classes.root}>
      {!loading && children}

      {loading && (
        <div className={`${classes.preload} ${classes.preload_container}`}>
          <div className={classes.preload_logo}>
            <img className={classes.logo} src={IMAGES.logo} alt="" />
          </div>
        </div>
      )}
      {enableBg && (
        <div
          className={classes.backgroundLayer}
          style={{ backgroundImage: `url(${IMAGES.logoBlur})` }}
        ></div>
      )}
    </div>
  );
};

export default memo(Container);
