import React, { memo } from "react";
import classes from "./index.module.scss";
import { Flex } from "@mantine/core";

import { fetchCoinDetails } from "../../../utils/fetchCoinDetails";
import moment from "moment";

interface IHistoryCard {
  data: TPayouts;
}

const HistoryCard: React.FC<IHistoryCard> = ({
  data: { currency, createdAt, status, amount, amountInUsdt, payoutFee },
}) => {
  const getColor = () => {
    switch (status) {
      case "Complete":
        return "#25c866";
      case "Rejected":
        return "#d32f2f";
      case "Confirming":
        return "#F89900";
      case "Processing":
        return "#F89900";
    }
  };

  return (
    <Flex className={classes.row} direction={"column"}>
      <Flex align={"center"} justify={"space-between"} mt={10}>
        <Flex align={"center"} justify={"flex-end"}>
          <div className={classes.svgIconContainer}>
            {fetchCoinDetails(currency as TCurrencyData["code"]).icon}
          </div>
          <div className={classes.title}>
            <p> {fetchCoinDetails(currency as TCurrencyData["code"]).name} </p>
            <span> {moment(createdAt).format("lll")} </span>
          </div>
        </Flex>

        <div className={classes.content}>
          <div className={classes.rateChange}>
            <div className={classes.rate}>
              <p className={classes.coinRate}>
                {" "}
                <span className={classes.positive}>+</span>{" "}
                {amount.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}{" "}
                {currency}
              </p>
              <p className={classes.coinRateMinus}>
                {" "}
                <span className={classes.negative}>-</span>{" "}
                {amountInUsdt.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })}
                {" USDT"}
              </p>
              <p
                className={`${classes.coinRateMinus} ${classes.processingFee}`}
              >
                <span
                  className={`${classes.negative} ${classes.processingFee}`}
                >
                  -
                </span>{" "}
                {payoutFee}
                {" USDT"} Platform fee
              </p>
            </div>
          </div>
        </div>
      </Flex>
      <p style={{ color: getColor() }} className={classes.status}>
        {status === "Processing" ? "Processing" : status}
      </p>
    </Flex>
  );
};

export default memo(HistoryCard);
