import React, { memo } from "react";
import classes from "./index.module.scss";

import { Button as MButton } from "@mantine/core";
interface IProps {
  title: string;
  type: "button" | "reset" | "submit";
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}

const Button: React.FC<IProps> = ({
  title,
  type = "button",
  onClick,
  disabled = false,
  loading = false,
}) => {
  return (
    <MButton
      loading={loading}
      disabled={disabled || loading}
      type={type}
      classNames={{ root: classes.root }}
      onClick={onClick}
    >
      {title}
    </MButton>
  );
};

export default memo(Button);
