import { useRouteError } from "react-router-dom";
import classes from "./ErrorPage.module.css";

const ErrorPage = () => {
  const error: unknown = useRouteError();
  const newError = error as {
    status: number;
    statusText: string;
    message: string;
  };

  return (
    <div className={classes.root}>
      <h1 style={{ color: "#fff" }}>{newError.status}</h1>
      <h2>Page {newError.statusText || newError.message}</h2>
      <p>
        <i>The resource requested could not be found on this server!</i>
      </p>
    </div>
  );
};

export default ErrorPage;
