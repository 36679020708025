import {
  ActionIcon,
  Box,
  Container,
  CopyButton,
  Flex,
  List,
  Text,
} from "@mantine/core";
import classes from "./index.module.scss";

import { FC } from "react";

import { IconCheck, IconCopy } from "@tabler/icons-react";
import Button from "../../../ui/button";

interface IPaymentInitiate {
  paymentInfo: TInvoices;
}
const PaymentInitiate: FC<IPaymentInitiate> = ({ paymentInfo }) => {
  return (
    <Container className={classes.main}>
      <Box className={classes.sectionOne}>
        <Flex justify={"center"}>
          <span className={`${classes.circle_box} ${classes.lg}`}>
            <IconCheck color="white" size={50} />
          </span>
        </Flex>
        <Text className={classes.title}>Your deposit in Processing</Text>
      </Box>
      <Box mt={60} className={classes.sectionTwo}>
        <List className={classes.list}>
          <List.Item>Amount: ${paymentInfo.amount.toFixed(2)}</List.Item>
          <List.Item>
            <Flex align={"center"}>
              Deposit reference number : {paymentInfo.orderId}{" "}
              <CopyButton value={paymentInfo.orderId} timeout={2000}>
                {({ copied, copy }) => (
                  <ActionIcon
                    mx={5}
                    size={"xs"}
                    color={copied ? "teal" : "white"}
                    variant="subtle"
                    onClick={copy}
                  >
                    {copied ? <IconCheck /> : <IconCopy />}
                  </ActionIcon>
                )}
              </CopyButton>
            </Flex>
          </List.Item>
          <List.Item>
            <Flex align={"center"}>
              Deposit Tracking Id : {paymentInfo.trackId}{" "}
              <CopyButton value={paymentInfo.trackId} timeout={2000}>
                {({ copied, copy }) => (
                  <ActionIcon
                    mx={5}
                    size={"xs"}
                    color={copied ? "teal" : "white"}
                    variant="subtle"
                    onClick={copy}
                  >
                    {copied ? <IconCheck /> : <IconCopy />}
                  </ActionIcon>
                )}
              </CopyButton>
            </Flex>
          </List.Item>
        </List>
        <Box className={classes.timerText}>
          Your deposit is being processed and typically takes 1-2 minutes for
          network block confirmation. Once confirmed, it will be approved and
          credited to your wallet shortly. Thank you for your patience.
        </Box>

        <Flex w={150} mt={30} mx={"auto"}>
          <Button
            onClick={() => window.open(paymentInfo.payLink, "_blank")}
            title="Deposit Now"
            type="button"
          />
        </Flex>
      </Box>
    </Container>
  );
};

export default PaymentInitiate;
