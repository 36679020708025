import React, { useEffect, useMemo } from "react";
import Container from "../container";
import BackBtnHeader from "../components/backBtn-header";
import PaymentInitiate from "../components/checkout/payment-initiate/PaymentInitiate";
import { useInvoiceInfoQuery } from "../hooks/invoices/query/useInvoiceInfo.query";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../redux-app/hooks";
import { useWebsocket } from "../services/socket.service";
import PaymentSuccess from "../components/checkout/payment-success/PaymentSuccess";

const PaymentCheckout = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { _id } = useAppSelector((state) => state.user.userData);
  const { socket } = useWebsocket();

  const { data, isLoading, refetch } = useInvoiceInfoQuery(invoiceId ?? "");

  const invoiceData: TInvoices = useMemo(() => {
    if (!isLoading && data?.status === "success") {
      return data.data;
    } else {
      return undefined;
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (socket) {
      socket.on("payment-status-updated", ({ userId, status }) => {
        if (_id === userId) {
          refetch();
        }
      });
    }
    return () => {
      socket?.off("payment-status-updated");
    };
  }, [socket, refetch, _id]);

  return (
    <Container loading={isLoading}>
      <BackBtnHeader heading={"Checkout"} />
      {invoiceData?.status === "paid" && (
        <PaymentSuccess
          message="Your purchase has been successfully processed, and the cryptocurrency has been transferred to your wallet. Thank you for using our Discounted Alts!"
          title="Buy Request Completed"
        />
      )}
      {invoiceData?.status === "unpaid" && (
        <PaymentInitiate paymentInfo={invoiceData} />
      )}
    </Container>
  );
};

export default PaymentCheckout;
