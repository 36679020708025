import { Box, Container, Flex, Image, Text } from "@mantine/core";
import classes from "./PaymentWaiting.module.scss";
import { IMAGES } from "../../../assets/images";
import Button from "../../../ui/button";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface IPaymentWaiting {
  title: string;
  message: string;
}

const PaymentWaiting: FC<IPaymentWaiting> = ({ message, title }) => {
  const navigate = useNavigate();
  return (
    <Container className={classes.main}>
      <Box className={classes.sectionOne}>
        <Image src={IMAGES.clock} className={classes.clockLogo} />
        <Text className={classes.title}>{title}</Text>
        <Flex className={classes.timerText} justify={"center"}>
          {message}
        </Flex>

        <Flex mt={30}>
          <Button onClick={() => navigate(-1)} title="Go Back" type="button" />
        </Flex>
      </Box>
    </Container>
  );
};

export default PaymentWaiting;
