import { Button, Flex } from "@mantine/core";
import React from "react";
import classes from "./index.module.scss";
import { useAppSelector } from "../../../redux-app/hooks";
import { fetchCoinDetails } from "../../../utils/fetchCoinDetails";
import { useNavigate } from "react-router-dom";
import { checkInsufficientBalance } from "../../../middleware/checkInsufficientBalance";
import { SVG } from "../../../assets/svg";

const TokensList = () => {
  const { currencies } = useAppSelector((state) => state.currencyRates);
  const navigate = useNavigate();

  return (
    <Flex style={{ flexDirection: "column" }} className={classes.root}>
      {currencies.map((currency) => (
        <div className={classes.row}>
          {fetchCoinDetails(currency.code).icon}
          <div className={classes.content}>
            <div className={classes.title}>
              <Flex align={"center"}>
                <p> {currency.code} </p>{" "}
                <div className={`${classes.offPercentage}`}>
                  {currency?.fluctuatedPercent?.toFixed(2) ??
                    currency.offerPercent}
                  % lesser
                </div>
              </Flex>
              <Flex align={"center"}>
                <Flex align={"center"} className={classes.currencyRate}>
                  {SVG.tether({ size: 10, color: "#d32f2f" })}
                  {Number(currency.rate).toLocaleString(undefined, {
                    maximumFractionDigits: currency.code === "TRX" ? 4 : 2,
                    minimumFractionDigits: currency.code === "TRX" ? 4 : 2,
                  })}{" "}
                </Flex>
                <div className={`${classes.offPrice}`}>
                  <Flex align={"center"}>
                    {SVG.tether({ size: 12, color: "#25c866" })}
                    {Number(currency.offerPrice).toLocaleString(undefined, {
                      maximumFractionDigits: currency.code === "TRX" ? 4 : 2,
                      minimumFractionDigits: currency.code === "TRX" ? 4 : 2,
                    })}
                  </Flex>
                </div>
              </Flex>
            </div>
          </div>

          <Button
            onClick={() => {
              checkInsufficientBalance(currency.offerPrice, navigate, () =>
                navigate(`/buy-token/${currency.code.toLocaleLowerCase()}`)
              );
            }}
            size="xs"
            classNames={{ root: classes.buyBtn }}
          >
            Buy
          </Button>
        </div>
      ))}
    </Flex>
  );
};

export default TokensList;
