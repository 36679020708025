export const clients = [
  {
    name: "Binance",
    icon: "https://assets.coingecko.com/markets/images/52/standard/binance.jpg?1706864274",
  },
  {
    name: "Bybit",
    icon: "https://assets.coingecko.com/markets/images/698/standard/bybit_spot.png?1706864649",
  },
  {
    name: "kraken",
    icon: "https://assets.coingecko.com/markets/images/29/standard/kraken.jpg?1706864265",
  },
  {
    name: "WeChat",
    icon: "https://assets.coingecko.com/markets/images/96/standard/WeChat_Image_20220117220452.png?1706864283",
  },
  {
    name: "Coinbase Coin",
    icon: "https://assets.coingecko.com/markets/images/23/standard/Coinbase_Coin_Primary.png?1706864258",
  },
  {
    name: "OKX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/294.png",
  },
  {
    name: "Upbit",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/351.png",
  },
  {
    name: "Bitget",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/513.png",
  },
  {
    name: "KuCoin",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/311.png",
  },
  {
    name: "HTX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/102.png",
  },
  {
    name: "MEXC",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/544.png",
  },
  {
    name: "Gate.io",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/37.png",
  },
  {
    name: "BingX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1064.png",
  },
  {
    name: "Crypto.com Exchange",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1149.png",
  },
  {
    name: "Binance TR",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1375.png",
  },
  {
    name: "BitMart",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/406.png",
  },
  {
    name: "Bitstamp",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/70.png",
  },
  {
    name: "Bithumb",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/200.png",
  },
  {
    name: "LBank",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/333.png",
  },
  {
    name: "XT.COM",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/525.png",
  },
  {
    name: "Tokocrypto",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/710.png",
  },
  {
    name: "bitFlyer",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/139.png",
  },
  {
    name: "Binance.US",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/630.png",
  },
  {
    name: "Gemini",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/151.png",
  },
  {
    name: "Deepcoin",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1182.png",
  },
  {
    name: "Biconomy.com",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/937.png",
  },
  {
    name: "AscendEX (BitMax)",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/453.png",
  },
  {
    name: "UZX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9181.png",
  },
  {
    name: "WEEK",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/5751.png",
  },
  {
    name: "COinW",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/517.png",
  },
  {
    name: "Toolbit",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/6137.png",
  },
  {
    name: "ProBit Global",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/477.png",
  },
  {
    name: "Binance TH",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9662.png",
  },
  {
    name: "DigiFinex",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/407.png",
  },
  {
    name: "P2B",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/215.png",
  },
  {
    name: "KCEX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9867.png",
  },
  {
    name: "Pionex",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/501.png",
  },
  {
    name: "BVOX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/7618.png",
  },
  {
    name: "OrangeX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/8125.png",
  },
  {
    name: "Bitrue",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/433.png",
  },
  {
    name: "FameEX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1247.png",
  },
  {
    name: "Hotcoin",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/943.png",
  },
  {
    name: "BiFinance",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9606.png",
  },
  {
    name: "Bitvavo",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/520.png",
  },
  {
    name: "Toolbit",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9218.png",
  },
  {
    name: "Hibt",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/6137.png",
  },
  {
    name: "Bitunix",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/7302.png",
  },
  {
    name: "Tapbit",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1645.png",
  },
  {
    name: "Coincheck",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/106.png",
  },
  {
    name: "Azbit",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1117.png",
  },
  {
    name: "HashKey Global",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9798.png",
  },
  {
    name: "LATOKEN",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/380.png",
  },
  {
    name: "Coinstore",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1411.png",
  },
  {
    name: "Zaif",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/73.png",
  },
  {
    name: "Ourbit",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/10153.png",
  },
  {
    name: "Bitso",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/125.png",
  },
  {
    name: "Coinone",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/174.png",
  },
  {
    name: "Bitbank",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/257.png",
  },
  {
    name: "Bitkub",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/436.png",
  },
  {
    name: "BigONE",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/330.png",
  },
  {
    name: "Raydium",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1342.png",
  },
  {
    name: "Uniswap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1069.png",
  },
  {
    name: "Curve (Ethereum)",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1063.png",
  },
  {
    name: "Orca",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1426.png",
  },
  {
    name: "PancakeSwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/6706.png",
  },
  {
    name: "THORChain",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1327.png",
  },
  {
    name: "Osmosis",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1447.png",
  },
  {
    name: "Balancer",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1404.png",
  },
  {
    name: "SushiSwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1141.png",
  },
  {
    name: "LFJ",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1456.png",
  },
  {
    name: "Bancor Network",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/267.png",
  },
  {
    name: "SpookySwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1455.png",
  },
  {
    name: "Pangolin",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1340.png",
  },
  {
    name: "Meteora VD",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9882.png",
  },
  {
    name: "Dexalot",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1598.png",
  },
  {
    name: "DeGate",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9329.png",
  },
  {
    name: "Helix",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1551.png",
  },
  {
    name: "WOOFi",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1675.png",
  },
  {
    name: "StellarTerm",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/249.png",
  },
  {
    name: "SpiritSwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1359.png",
  },
  {
    name: "Aerodrome SlipStream",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/10023.png",
  },
  {
    name: "Cetus",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/6993.png",
  },
  {
    name: "OpenOcean",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1261.png",
  },
  {
    name: "THENA FUSION",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/6407.png",
  },
  {
    name: "SunSwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1116.png",
  },
  {
    name: "PulseX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/7126.png",
  },
  {
    name: "DeFiChain DEX",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1235.png",
  },
  {
    name: "Thruster ",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9515.png",
  },
  {
    name: "WigoSwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1594.png",
  },
  {
    name: "Loopring Exchange",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/983.png",
  },
  {
    name: "DODO (BSC)",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1319.png",
  },
  {
    name: "Swappi",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1646.png",
  },
  {
    name: "Katana",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1543.png",
  },
  {
    name: "Solidly",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1606.png",
  },
  {
    name: "STON.fi",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/5745.png",
  },
  {
    name: "VVS Finance",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1530.png",
  },
  {
    name: "DeDust",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/5631.png",
  },
  {
    name: "ShibaSwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1408.png",
  },
  {
    name: "Agni Finance ",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/7731.png",
  },
  {
    name: "KLAYswap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1232.png",
  },
  {
    name: "Minswap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1677.png",
  },
  {
    name: "Koi Finance",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/6661.png",
  },
  {
    name: "Polkaswap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1101.png",
  },
  {
    name: "Ubeswap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1339.png",
  },
  {
    name: "Solarbeam",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1482.png",
  },
  {
    name: "Dragonswap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/10363.png",
  },
  {
    name: "KnightSwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1584.png",
  },
  {
    name: "Cellana Finance",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/10330.png",
  },
  {
    name: "Lynex Fusion",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/7957.png",
  },
  {
    name: "VyFinance",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/7336.png",
  },
  {
    name: "Kujira Fin",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1707.png",
  },
  {
    name: "BakerySwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1302.png",
  },
  {
    name: "DackieSwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/7449.png",
  },
  {
    name: "WX Network",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/246.png",
  },
  {
    name: "Retro",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/7516.png",
  },
  {
    name: "Sovryn",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1407.png",
  },
  {
    name: "Honeyswap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1190.png",
  },
  {
    name: "ZilSwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1493.png",
  },
  {
    name: "1INTRO",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9952.png",
  },
  {
    name: "Swop.fi",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1297.png",
  },
  {
    name: "Netswap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1570.png",
  },
  {
    name: "EtherVista",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/10912.png",
  },
  {
    name: "Nash",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1070.png",
  },
  {
    name: "Karura Swap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1670.png",
  },
  {
    name: "MerlinSwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9651.png",
  },
  {
    name: "DogeSwap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1458.png",
  },
  {
    name: "Sonic",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/8513.png",
  },
  {
    name: "DeFi Swap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1688.png",
  },
  {
    name: "Balanced",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/1428.png",
  },
  {
    name: "Blasterswap",
    icon: "https://s2.coinmarketcap.com/static/img/exchanges/64x64/9525.png",
  },
];
