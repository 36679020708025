import React, { ChangeEventHandler } from "react";
import { Control, Controller } from "react-hook-form";
import classes from "./index.module.scss";
import { Flex } from "@mantine/core";

interface IProps {
  name: string;
  control: Control<any>;
  placeholder: string;
  label: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  border?: boolean;
  disabled?: boolean;

  rightSection?: React.ReactNode;
}

const InputField: React.FC<IProps> = ({
  name,
  control,
  placeholder,
  label,
  onChange,
  border = false,
  disabled = false,
  rightSection,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      render={({ field, formState: { errors } }) => (
        <>
          <p className={classes.label}> {label} </p>
          <Flex
            justify={"center"}
            align={"center"}
            className={`${classes.inputFieldRoot} ${border && classes.border}`}
          >
            <input
              className={`${classes.inputField} ${border && classes.border}`}
              placeholder={placeholder}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                onChange?.(e);
              }}
              disabled={disabled}
            />
            {rightSection}
          </Flex>
          {errors?.[name]?.message && (
            <span className={classes.errorText}>
              {typeof errors[name]?.message === "string"
                ? (errors[name]?.message as string)
                : "Error"}
            </span>
          )}
        </>
      )}
    />
  );
};

export default InputField;
