import React, { useEffect } from "react";

interface TawkWidgetProps {
  propertyId: string; // Tawk.to property ID
  customIcon?: string; // Optional: Custom icon URL
  bottom?: string; // Optional: Distance from the bottom
  right?: string; // Optional: Distance from the right
}

const TawkWidget: React.FC<TawkWidgetProps> = ({
  propertyId,
  customIcon = "/default-chat-icon.png", // Fallback icon
  bottom = "50px",
  right = "30px",
}) => {
  useEffect(() => {
    // Load the Tawk.to script

    const script = document.createElement("script");
    script.async = true;
    script.src = `https://embed.tawk.to/${propertyId}/1igtf2g8v`;
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    document.body.appendChild(script);

    // Cleanup on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, [propertyId]);

  // const handleIconClick = () => {
  //   if (window.Tawk_API) {
  //     window.Tawk_API.toggle(); // Toggles the Tawk.to widget
  //   }
  // };

  return null;
};

export default TawkWidget;
