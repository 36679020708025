import * as Yup from "yup";
import { store } from "../../redux-app/store";

export const depositValidation = () => {
  const { usdtDeposit } =
    store.getState().settings.data.minimumTransactionLimits;

  return Yup.object().shape({
    amount: Yup.number()
      .typeError("Enter a deposit amount")
      .required("Enter a deposit amount")
      .min(usdtDeposit, "Minimum deposit amount is " + usdtDeposit),
  });
};
