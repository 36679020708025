import { Box, Button, Flex, Modal } from "@mantine/core";
import React, { FC, memo } from "react";
import classes from "./index.module.scss";
import { useForm } from "react-hook-form";
import { forms } from "../../../../forms";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDeactivateAccountMutation } from "../../../../hooks/auth/useDeactivateAccount.mutation";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../ui/input-field";
import { notifications } from "@mantine/notifications";

interface IDeactivationModal {
  show: boolean;
  handelClose: () => void;
}

const DeactivationModal: FC<IDeactivationModal> = ({ handelClose, show }) => {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: forms.login.values,
    resolver: yupResolver(forms.login.validation),
    reValidateMode: "onChange",
    mode: "all",
  });

  const signOut = useSignOut();
  const navigate = useNavigate();

  const { mutateAsync, isPending } = useDeactivateAccountMutation();

  const onSubmit = async (data: typeof forms.login.values) => {
    const res = await mutateAsync(data);

    reset();
    if (res.status === "success") {
      signOut();
      localStorage.clear();
      navigate("/login");
    } else {
      notifications.show({
        message: res.data?.message,
        color: "red",
      });
    }
  };
  return (
    <Modal
      centered
      size={400}
      withCloseButton={false}
      opened={show}
      onClose={handelClose}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      transitionProps={{
        transition: "fade-down",
        duration: 200,
        timingFunction: "linear",
      }}
      radius={8}
      styles={{ content: { backgroundColor: "#222223" }, body: { padding: 0 } }}
    >
      <div className={`${classes.success_box}`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box p={10}>
            <div className={classes.header}>
              <h4 className={classes.heading}> {"Confirmation"} </h4>
              <p className={classes.message}>
                {"Please enter your private key for account deactivation"}
              </p>
            </div>

            <Flex my={10} w={"100%"} direction={"column"}>
              <InputField
                border
                label={""}
                placeholder={"xxxxxxxxxxxxxxxxxxxx"}
                name={"loginKey"}
                control={control}
              />
            </Flex>
          </Box>
          <div className={classes.btnSection}>
            <button
              onClick={handelClose}
              className={`${classes.btn} ${classes.borderRight}`}
            >
              Cancel
            </button>
            <Button
              type="submit"
              loading={isPending}
              classNames={{ root: `${classes.btn} ${classes.btnActive}` }}
            >
              {"Deactivate"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default memo(DeactivationModal);
