import { useMutation } from "@tanstack/react-query";
import { request } from "../../services/axios.service";

import { apiUrls } from "../apiUrls";
import { forms } from "../../forms";

const deactivate = async (data: typeof forms.login.values) => {
  const response: TServerResponse = await request({
    url: apiUrls.auth.DEACTIVATE_ACCOUNT,
    method: "POST",
    data: data,
  });
  return response;
};

export const useDeactivateAccountMutation = () => {
  return useMutation({ mutationFn: deactivate });
};
