import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISettingState {
  data: TSettingsModel;
}

const initialState: ISettingState = {
  data: {
    _id: "",
    socialLinks: {
      instagram: "",
      telegram: "",
      facebook: "",
      youtube: "",
      telegramChannel: "",
    },
    paymentMethods: {
      bep20: [],
      trc20: [],
      detailsChangingTime: 3,
    },
    currencyOfferRate: {
      BNB: 1,
      BTC: 1,
      ETH: 1,
      SOL: 1,
    },
    minimumTransactionLimits: {
      usdtDeposit: 100,
    },
    appInformation: {
      discountedToken: {
        message: "",
        title: "",
      },
    },
    blockchainNetworkTypes: [],
  },
};

export const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateAppSettings: (state, action: PayloadAction<TSettingsModel>) => {
      state.data = action.payload;
    },
  },
});

export const { updateAppSettings } = settingSlice.actions;

export default settingSlice.reducer;
