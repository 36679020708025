import React, { memo } from "react";
import MarketCard from "./MarketCard";
import classes from "./index.module.scss";
import { Carousel } from "@mantine/carousel";
import { useAppSelector } from "../../../redux-app/hooks";
import { Flex } from "@mantine/core";
import ShowBuyTokenPopovers from "../../../socket-events/token-popover/ShowBuyTokenPopovers";

const Market: React.FC = () => {
  const { currencies } = useAppSelector((state) => state.currencyRates);

  return (
    <div className={classes.root}>
      <Flex align={"center"} justify={"space-between"} mih={70}>
        <h5 className={classes.heading}>Market</h5>
        <ShowBuyTokenPopovers />
      </Flex>
      <div className={classes.cardContainer}>
        <Carousel
          withIndicators={false}
          height={150}
          slideSize="0%"
          slideGap="sm"
          loop
          align="start"
          slidesToScroll={1}
          withControls={false}
        >
          {currencies.map((currency) => (
            <Carousel.Slide key={currency._id}>
              <MarketCard data={currency} />
            </Carousel.Slide>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default memo(Market);
