import React, { FC } from "react";
import { Motion, spring } from "react-motion";
import classes from "./index.module.scss";
import { Flex, Image, Text } from "@mantine/core";
import { IMAGES } from "../../../../assets/images";

export interface ITokenBuyPopover {
  data: {
    username: string;
    profile: string;
    dynamicAmount: number;
    randomCurrency: TCurrencyData["code"];
    coinDetails: TCurrencyData;
    client: string;
  };
  fadeOut: boolean;
}

const TokenBuyPopover: FC<ITokenBuyPopover> = ({ data, fadeOut }) => {
  const {
    dynamicAmount,

    randomCurrency,
    username,
    client,
    coinDetails,
  } = data;
  return (
    <Motion
      defaultStyle={{ x: 150, opacity: 1 }} // Initial off-screen position
      style={{
        x: spring(0, { stiffness: 100, damping: 30 }), // Move to the center
        opacity: spring(fadeOut ? 0 : 1, { stiffness: 100, damping: 30 }), // Fade out if `fadeOut` is true
      }}
    >
      {(style) => (
        <div
          style={{
            transform: `translateX(${style.x}px)`,
            opacity: style.opacity,
          }}
          className={classes.div}
        >
          <Flex align={"center"}>
            <Image src={IMAGES.avatar} w={25} h={25} />
            <Text className={classes.text}>
              {username} purchased {dynamicAmount} {randomCurrency} from{" "}
              {client} Exchange using Discounted Alts at{" "}
              {coinDetails?.offerPrice?.toFixed(2)} USDT/
              {randomCurrency} ({coinDetails?.fluctuatedPercent?.toFixed(2)}%
              lower than market price)
            </Text>
          </Flex>
        </div>
      )}
    </Motion>
  );
};

export default TokenBuyPopover;
