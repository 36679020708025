import React, { useEffect, useMemo } from "react";
import Container from "../../container";
import BackBtnHeader from "../backBtn-header";
import { Box, Flex, Space, Text } from "@mantine/core";
import classes from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCoinDetails } from "../../utils/fetchCoinDetails";
import { useAppSelector } from "../../redux-app/hooks";
import { useForm } from "react-hook-form";
import { forms } from "../../forms";
import { yupResolver } from "@hookform/resolvers/yup";
import Wallet from "../deposit/components/wallet/Wallet";
import InputField from "../../ui/input-field";
import Button from "../../ui/button";
import { getCurrencyExchangeRate } from "../../utils/getCurrencyExchangeRate";
import ErrorText from "../../ui/error-text/ErrorText";
import { useCreatePayoutMutation } from "../../hooks/payout/mutation/useCreatePayout.mutation";
import ROUTES from "../../enum/routes";

const BuyTokenScreen = () => {
  const { code } = useParams<{ code: TCurrencyData["code"] }>();
  const { currencies } = useAppSelector((state) => state.currencyRates);
  const { usdtWallet } = useAppSelector((state) => state.user.userData);
  const { blockchainNetworkTypes } = useAppSelector(
    (state) => state.settings.data
  );
  const navigate = useNavigate();

  const { isPending, mutateAsync } = useCreatePayoutMutation();

  const { code: coin, icon } = useMemo(() => {
    return fetchCoinDetails(
      (code?.toUpperCase() as TCurrencyData["code"]) ?? "USDT"
    );
  }, [code]);

  const rate = useMemo(() => {
    return currencies.find((value) => value.code === coin)?.offerPrice ?? 0;
  }, [coin, currencies]);

  const livePrice = useMemo(() => {
    return currencies.find((value) => value.code === coin)?.rate ?? 0;
  }, [coin, currencies]);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ...forms.buyToken.values,
      amount: 0,
    },
    resolver: yupResolver<any>(
      forms.buyToken.validation(0, usdtWallet.totalAmount)
    ),
    reValidateMode: "onSubmit",
    mode: "all",
  });

  const onSubmit = async (values: typeof forms.buyToken.values) => {
    customAlert.show({
      title: "Confirmation",
      message: `Please verify your wallet address carefully. Your cryptocurrency will be credited within 1-2 minutes after network block confirmation. A 1 USDT platform fee will be deducted for this transaction.`,
      type: "success",
      okCallBack: () => onPayout(values),
      cancelTitle: "Cancel",
      okTitle: "Proceed",
    });
  };

  const onPayout = async (values: typeof forms.buyToken.values) => {
    const res = await mutateAsync({
      ...values,
      currency: coin,
      discountedAltPrice: rate,
      livePrice: livePrice,
    });

    if (res.status === "success") {
      navigate(ROUTES.PAYOUT_STATUS.replace(":trackId", res.data.trackId));

      reset();
    } else {
      customAlert.show({
        type: "error",
        title: res.data.title,
        message: res.data.message,
      });
    }
  };

  const userAmount = watch("amountInUsdt");

  useEffect(() => {
    setValue("amountInUsdt", userAmount);
    setValue(
      "amount",
      Number(
        getCurrencyExchangeRate(Number(userAmount), rate, "usdt").toFixed(
          coin === "TRX" ? 4 : 8
        )
      )
    );
  }, [rate, setValue, userAmount, coin]);

  return (
    <Container>
      <BackBtnHeader heading="Buy Token" />
      <Space my={30} />
      <Wallet />

      <Flex mt={25} align={"center"} justify={"space-between"}>
        <Flex align={"center"}>
          {icon}
          <div style={{ marginLeft: 10 }} className={classes.title}>
            Buy {coin}
          </div>
        </Flex>
        <div className={`${classes.offPrice}`}>
          {Number(rate).toLocaleString(undefined, {
            maximumFractionDigits: coin === "TRX" ? 4 : 2,
            minimumFractionDigits: coin === "TRX" ? 4 : 2,
          })}{" "}
          USDT
        </div>
      </Flex>

      <Flex w={"100%"}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Flex my={25} direction={"column"}>
            <Text
              size="sm"
              variant="text"
              c={"#7e8088"}
            >{`Enter USDT Amount`}</Text>

            <InputField
              control={control}
              rightSection={
                <Text
                  onClick={() =>
                    setValue(
                      "amountInUsdt",
                      Number(usdtWallet.totalAmount - 1).toFixed(2)
                    )
                  }
                  style={{ cursor: "pointer" }}
                  fw={"bold"}
                  c={"#25c866"}
                  px={15}
                >
                  Max
                </Text>
              }
              label={""}
              name={"amountInUsdt"}
              placeholder={`Enter amount in USDT`}
              onChange={(e) =>
                setValue(
                  "amount",
                  Number(
                    getCurrencyExchangeRate(
                      Number(e.target.value),
                      rate,
                      "usdt"
                    ).toFixed(coin === "TRX" ? 4 : 8)
                  )
                )
              }
            />
          </Flex>
          <Flex my={25} direction={"column"}>
            <InputField
              disabled
              control={control}
              label={`${code?.toUpperCase()} Amount to Be Received`}
              name={"amount"}
              placeholder={`Enter amount in ${code?.toUpperCase()}`}
              onChange={(e) =>
                setValue(
                  "amountInUsdt",
                  Number(
                    getCurrencyExchangeRate(
                      Number(e.target.value),
                      rate,
                      "token"
                    ).toFixed(coin === "TRX" ? 4 : 8)
                  )
                )
              }
            />
          </Flex>

          <Flex direction={"column"} my={25}>
            <p className={classes.label}> {"Select blockchain type"} </p>
            <Flex>
              {blockchainNetworkTypes.map((networkType) => {
                if (networkType.type === coin) {
                  return (
                    <Box
                      key={networkType._id}
                      onClick={() =>
                        setValue("network", networkType.label.toLowerCase())
                      }
                      className={`${classes.blockchainBox} ${
                        watch("network") === networkType.label.toLowerCase() &&
                        classes.activeBlockchain
                      }`}
                    >
                      {networkType.label}
                    </Box>
                  );
                } else {
                  return null;
                }
              })}
            </Flex>
            {errors?.network && (
              <span className={classes.errorText}>
                {errors?.network?.message as string}
              </span>
            )}
          </Flex>
          {watch("network") === "ton" && (
            <Flex my={25} direction={"column"}>
              <InputField
                control={control}
                label={`Memo`}
                name={"memo"}
                placeholder={`Enter your memo address`}
              />
            </Flex>
          )}

          <Flex my={25} direction={"column"}>
            <InputField
              control={control}
              label={`Blockchain Address`}
              name={"address"}
              placeholder={`Enter your blockchain address`}
            />
          </Flex>

          <ErrorText text="Important: Please ensure the wallet address you enter is correct. Double-check before submitting. If an incorrect address is used, the assets may be lost and cannot be recovered." />

          <Flex my={20}>
            <Button loading={isPending} title={"Buy"} type="submit" />
          </Flex>
        </form>
      </Flex>
    </Container>
  );
};

export default BuyTokenScreen;
