import React, { useEffect, useMemo } from "react";
import Container from "../../container";
import BackBtnHeader from "../backBtn-header";
import { Center, Drawer } from "@mantine/core";
import { useNotificationsQuery } from "../../hooks/notifications/query/useNotifications.query";
import SingleNotification from "./components/single-notification/SingleNotification";
import moment from "moment";

interface INotification {
  opened: boolean;
  close: () => void;
}

const NotificationScreen: React.FC<INotification> = ({ close, opened }) => {
  const { isLoading, data, refetch } = useNotificationsQuery();

  const notifications: TNotifications[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (opened) refetch();
  }, [opened, refetch]);

  return (
    <Drawer
      transitionProps={{
        transition: "fade-left",
        duration: 200,
        timingFunction: "linear",
      }}
      size={"100%"}
      withCloseButton={false}
      opened={opened}
      onClose={close}
      overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      position="right"
      styles={{
        content: { backgroundColor: "#11150f" },
        body: { padding: 0 },
      }}
    >
      <Container loading={isLoading}>
        <BackBtnHeader handleBack={close} heading={"Notification"} />
        {notifications.length === 0 && (
          <Center mih={"80vh"}>
            <h4>No notification found</h4>
          </Center>
        )}
        {notifications?.map((notification) => (
          <SingleNotification
            key={notification._id}
            data={{
              message: notification.message,
              time: moment(notification.createdAt).fromNow(),
            }}
          />
        ))}
      </Container>
    </Drawer>
  );
};

export default NotificationScreen;
