import { useMutation } from "@tanstack/react-query";
import { request } from "../../services/axios.service";
import { apiUrls } from "../apiUrls";

const signUp = async () => {
  const response: TServerResponse = await request({
    url: apiUrls.auth.SIGN_UP,
    method: "POST",
  });
  return response;
};

export const useSignUpMutation = () => {
  return useMutation({ mutationFn: signUp });
};
