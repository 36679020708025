import { useCallback, useEffect, useState } from "react";
import { useWebsocket } from "../../services/socket.service";
import { useAppSelector } from "../../redux-app/hooks";
import TokenBuyPopover, {
  ITokenBuyPopover,
} from "../../components/home/market/user-popover/TokenBuyPopover";
import { clients } from "../../components/home/clients/data";

const ShowBuyTokenPopovers = () => {
  const { socket } = useWebsocket();
  const { currencies } = useAppSelector((state) => state.currencyRates);
  const [notificationData, setNotificationData] = useState<
    ITokenBuyPopover | undefined
  >(undefined); // Store WebSocket data
  const [showNotification, setShowNotification] = useState(false); // Control visibility
  const [fadeOut, setFadeOut] = useState(false); // Control fade-out effect
  const showRandomClient = () => {
    const randomIndex = Math.floor(Math.random() * clients.length);
    return clients[randomIndex];
  };

  const fetchCoinDetails = useCallback(
    (currency: TCurrencyData["code"]) => {
      return currencies.find((find) => find.code === currency);
    },
    [currencies]
  );

  useEffect(() => {
    if (socket) {
      socket.on(
        "show-dummy-token-popover",
        ({ username, profile, dynamicAmount, randomCurrency }) => {
          const coinDetails = fetchCoinDetails(randomCurrency);

          if (coinDetails)
            setNotificationData({
              data: {
                username,
                profile,
                dynamicAmount,
                randomCurrency,
                coinDetails,
                client: showRandomClient().name,
              },
              fadeOut,
            });
          setFadeOut(false); // Reset fade-out state
          setShowNotification(true); // Show the notification

          // Trigger fade-out effect after 4 seconds
          setTimeout(() => {
            setFadeOut(true);
          }, 3000);

          // Unmount the component after 5 seconds (1 second for fade-out)
          setTimeout(() => {
            setShowNotification(false);
          }, 4000);
        }
      );
    }
    return () => {
      socket?.off("show-dummy-token-popover");
    };
  }, [socket, fetchCoinDetails, fadeOut]);

  return (
    <>
      {showNotification && notificationData && (
        <TokenBuyPopover data={notificationData.data} fadeOut={fadeOut} />
      )}
    </>
  );
};

export default ShowBuyTokenPopovers;
