import { useQuery } from "@tanstack/react-query";
import { request } from "../../services/axios.service";
import { apiUrls } from "../apiUrls";
import { store } from "../../redux-app/store";

import { updateProfileData } from "../../redux-app/reducer/user/user-reducer";

const getProfile = async () => {
  const response: TServerResponse = await request({
    url: apiUrls.auth.GET_PROFILE,
    method: "GET",
  });

  if (response.status === "success") {
    store.dispatch(updateProfileData(response.data));
  }

  return response;
};

export const useCheckAuthenticated = (enabled: boolean) => {
  return useQuery({
    queryKey: [apiUrls.auth.GET_PROFILE],
    queryFn: getProfile,
    enabled: enabled,
  });
};
