import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomAlertModal from "./CustomAlertModal";

const defaultValues: TGlobalAlertOb = {
  show: () => {},
};

export const CustomAlertContext =
  React.createContext<TGlobalAlertOb>(defaultValues);
export const CustomAlertConsumer = CustomAlertContext.Consumer;

type TAlertProps = {
  readonly children?: React.ReactNode;
};

const initials: AlertMessage = {
  title: "",
  message: "",
  type: "success",
};

function GlobalAlert(props: TAlertProps) {
  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState<AlertMessage>(initials);
  const globalAlertRef = useRef<TGlobalAlertOb>(defaultValues);

  const alert: TGlobalAlertOb = useMemo(
    () => ({
      show: (alertData: AlertMessage) => {
        setAlertProps(alertData);
        setShowAlert(true);
      },
    }),
    []
  );

  useEffect(() => {
    globalAlertRef.current = alert;

    // Assign the global alert reference to `global` or `window`
    if (typeof window !== "undefined") {
      (window as any).customAlert = globalAlertRef.current;
    } else if (typeof global !== "undefined") {
      (global as any).customAlert = globalAlertRef.current;
    }
  }, [alert]);

  return (
    <CustomAlertContext.Provider value={alert}>
      {props.children}
      <CustomAlertModal
        alertData={alertProps}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
      />
    </CustomAlertContext.Provider>
  );
}

export default GlobalAlert;
