import { Center, Drawer } from "@mantine/core";
import React, { useEffect, useMemo } from "react";
import Container from "../../container";
import BackBtnHeader from "../backBtn-header";

import HistoryCard from "./history-card/HistoryCard";
import { usePaymentHistoryQuery } from "../../hooks/payments/query/usePaymentHistory.query";
import { useWebsocket } from "../../services/socket.service";
import { useAppSelector } from "../../redux-app/hooks";

interface IDepositHistory {
  opened: boolean;
  close: () => void;
}

const DepositHistory: React.FC<IDepositHistory> = ({ close, opened }) => {
  const { isLoading, data, refetch } = usePaymentHistoryQuery();
  const { socket } = useWebsocket();
  const { _id } = useAppSelector((state) => state.user.userData);

  const history: TPayments[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (opened) {
      refetch();
    }

    if (socket) {
      socket.on("payment-history-updated", ({ userId }) => {
        if (_id === userId) {
          refetch();
        }
      });
    }
    return () => {
      socket?.off("payment-history-updated");
    };
  }, [opened, refetch, socket, _id]);

  return (
    <Drawer
      size={"100%"}
      withCloseButton={false}
      opened={opened}
      onClose={close}
      overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      transitionProps={{
        transition: "fade-left",
        duration: 200,
        timingFunction: "linear",
      }}
      position="right"
      styles={{
        content: { backgroundColor: "#11150f" },
        body: { padding: 0 },
      }}
    >
      <Container loading={isLoading}>
        <BackBtnHeader heading="Deposit History" handleBack={close} />

        {history.length === 0 && (
          <Center mih={"80vh"}>
            <h4>No history found</h4>
          </Center>
        )}
        {history.map((request) => (
          <HistoryCard key={request._id} data={request} />
        ))}
      </Container>
    </Drawer>
  );
};

export default DepositHistory;
