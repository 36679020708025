import React, { memo } from "react";
import Container from "../../container";
import classes from "./index.module.scss";
import TheHeader from "./header/TheHeader";
import WalletCard from "./wallet-card/WalletCard";
import Market from "./market/Market";
import CryptoRating from "./crypto-rating/CryptoRating";
import FooterHeader from "../footer-header/FooterHeader";
import Clients from "./clients/Clients";
import Banner from "./banner/Banner";

const HomeScreen: React.FC = () => {
  return (
    <Container>
      <TheHeader />
      <div className={classes.root}>
        <WalletCard />
        <Market />
        <CryptoRating />
        <Banner />
        <Clients />
      </div>
      <FooterHeader />
      {/* <Box
        component="a"
        href={data?.socialLinks?.telegramChannel}
        target="_blank"
        className={classes.floatingIcon}
      >
        <Image src={IMAGES.telegramSupport3D} style={{ width: 45 }} />
      </Box> */}
    </Container>
  );
};

export default memo(HomeScreen);
