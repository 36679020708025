import React, { memo } from "react";
import classes from "./index.module.scss";
import Button from "../../../ui/button";
import { Modal } from "@mantine/core";

interface IProps {
  show: boolean;
  handelClose: () => void;
  info: TSettingsModel["appInformation"]["discountedToken"];
}

const InformationModal: React.FC<IProps> = ({ handelClose, show, info }) => {
  return (
    <Modal
      centered
      size={343}
      withCloseButton={false}
      opened={show}
      onClose={handelClose}
      classNames={{
        content: classes.modal_content,
      }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      transitionProps={{
        transition: "fade-down",
        duration: 200,
        timingFunction: "linear",
      }}
      radius={8}
    >
      <div className={classes.modal_content}>
        <div>
          <h3 className={classes.heading}>{info.title}</h3>
          <div className={classes.text_small}>
            <p>{info.message}</p>
          </div>

          <div className={classes.acceptBtnContainer}>
            <Button title="OK" type={"button"} onClick={handelClose} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(InformationModal);
