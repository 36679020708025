import React, { memo } from "react";
import Container from "../../container";
import BackBtnHeader from "../backBtn-header";
import InputField from "../../ui/input-field";
import { useForm } from "react-hook-form";

import { forms } from "../../forms";
import { yupResolver } from "@hookform/resolvers/yup";
import Button from "../../ui/button";
import { ActionIcon, Box } from "@mantine/core";
import Wallet from "./components/wallet/Wallet";
import { useAppSelector } from "../../redux-app/hooks";
import { notifications } from "@mantine/notifications";
import { IconHistory } from "@tabler/icons-react";
import DepositHistory from "../deposit-history/DepositHistory";
import { useDisclosure } from "@mantine/hooks";
import { useInitiatePaymentMutation } from "../../hooks/invoices/mutation/useInitiatePayment.mutation";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../enum/routes";

const DepositScreen = () => {
  const [opened, { open, close }] = useDisclosure();
  const { minimumTransactionLimits } = useAppSelector(
    (state) => state.settings.data
  );
  const navigate = useNavigate();

  const { isPending, mutateAsync } = useInitiatePaymentMutation();
  const handler = useForm({
    defaultValues: {
      ...forms.deposit.values,
      amount: minimumTransactionLimits.usdtDeposit,
    },
    resolver: yupResolver<any>(forms.deposit.validation()),
    reValidateMode: "onChange",
    mode: "all",
  });
  const onSubmit = async (values: typeof forms.deposit.values) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      window.open(res.data.payLink, "_blank");
      navigate(ROUTES.PAYMENT_CHECKOUT.replace(":invoiceId", res.data._id));
    } else {
      notifications.show({
        color: "red",
        message: res.data.message,
      });
    }
  };
  return (
    <Container>
      <BackBtnHeader
        heading={"Deposit USDT"}
        rightSection={
          <ActionIcon onClick={open} color="white" variant="subtle">
            <IconHistory size={25} />
          </ActionIcon>
        }
      />
      <Wallet />

      <div>
        <form onSubmit={handler.handleSubmit(onSubmit)}>
          <InputField
            control={handler.control}
            label={"Deposit amount in USDT"}
            name={"amount"}
            placeholder={"Deposit amount in USDT"}
          />

          <Box my={20}>
            <Button
              loading={isPending}
              title={"Proceed to Pay"}
              type="submit"
            />
          </Box>
        </form>
      </div>
      <DepositHistory close={close} opened={opened} />
    </Container>
  );
};

export default memo(DepositScreen);
