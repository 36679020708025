import React from "react";

interface IProps {
  data: number[];
  strokeColor: string;
}

const LineChartSVG: React.FC<IProps> = ({ data, strokeColor }) => {
  const width = 280; // Smaller width
  const height = 50; // Smaller height
  const margin = 10;

  // Calculate the maximum and minimum values for scaling
  const xMax = data.length - 1;
  const yMax = Math.max(...data);
  const yMin = Math.min(...data);

  // Create scales
  const xScale = (index: number) =>
    margin + (width - 2 * margin) * (index / xMax);
  const yScale = (value: number) =>
    height - margin - (height - 2 * margin) * ((value - yMin) / (yMax - yMin));

  // Generate the line path using cubic Bezier curves for smoothness
  const linePath = data
    .map((value, index) => {
      const x = xScale(index);
      const y = yScale(value);
      return index === 0
        ? `M ${x} ${y}`
        : `C ${x - 10} ${y - 10}, ${x - 10} ${y + 10}, ${x} ${y}`;
    })
    .join(" ");

  return (
    <svg width={width} height={height}>
      <path d={linePath} stroke={strokeColor} strokeWidth="1" fill="none" />
    </svg>
  );
};

export default LineChartSVG;
