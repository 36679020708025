import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../apiUrls";

const get = async (invoiceId: string) => {
  const response: TServerResponse = await request({
    url: apiUrls.invoice.FETCH_INVOICE_INFO,
    method: "GET",
    params: {
      invoiceId,
    },
  });
  return response;
};

export const useInvoiceInfoQuery = (invoiceId: string) => {
  return useQuery({
    queryKey: [apiUrls.invoice.FETCH_INVOICE_INFO],
    queryFn: () => get(invoiceId),
  });
};
