import { useQuery } from "@tanstack/react-query";
import { apiUrls } from "../../apiUrls";
import { request } from "../../../services/axios.service";
import { store } from "../../../redux-app/store";
import {
  updateLatestGraph,
  updateLatestRates,
} from "../../../redux-app/reducer/currency-rates/currency-rates-reducer";

const get = async () => {
  const response: TServerResponse = await request({
    url: apiUrls.currency.FETCH_LATEST_COINS_RATE,
    method: "GET",
  });

  store.dispatch(updateLatestRates(response.data.currencies));
  store.dispatch(updateLatestGraph(response?.extraData ?? {}));
  return response;
};

export const useLatestCurrencyRateQuery = (enabled: boolean) => {
  return useQuery({
    queryKey: [apiUrls.currency.FETCH_LATEST_COINS_RATE],
    queryFn: get,
    enabled: enabled,
  });
};
