import React, { memo, useRef } from "react";
import classes from "./index.module.scss";
import { Carousel } from "@mantine/carousel";
import { Flex, Image } from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";
import { IMAGES } from "../../../assets/images";

const Banner: React.FC = () => {
  const autoplay = useRef(Autoplay({ delay: 1000 }));
  return (
    <div className={classes.root}>
      <h5 className={classes.heading}>Find the best Arbitrage opportunity</h5>
      <div className={classes.cardContainer}>
        <Carousel
          plugins={[autoplay.current]}
          withIndicators={false}
          height={150}
          slideSize="5%"
          slideGap="lg"
          loop
          align="start"
          withControls={false}
          onMouseLeave={autoplay.current.reset}
        >
          <Carousel.Slide>
            <Flex>
              <Image radius={"md"} src={IMAGES.banner1} w={310} fit="cover" />
            </Flex>
          </Carousel.Slide>
          <Carousel.Slide>
            <Flex>
              <Image radius={"md"} src={IMAGES.banner2} w={310} fit="contain" />
            </Flex>
          </Carousel.Slide>
          <Carousel.Slide>
            <Flex>
              <Image radius={"md"} src={IMAGES.banner3} w={310} fit="cover" />
            </Flex>
          </Carousel.Slide>
          <Carousel.Slide>
            <Flex>
              <Image radius={"md"} src={IMAGES.banner4} w={310} fit="cover" />
            </Flex>
          </Carousel.Slide>
        </Carousel>
      </div>
    </div>
  );
};

export default memo(Banner);
