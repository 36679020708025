import React, { memo, useMemo } from "react";
import classes from "./index.module.scss";
import { fetchCoinDetails } from "../../utils/fetchCoinDetails";
import { Box, Flex, Image, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { checkInsufficientBalance } from "../../middleware/checkInsufficientBalance";
import { SVG } from "../../assets/svg";
import { clients } from "../home/clients/data";

interface ISingleCoin {
  data: TCurrencyData;
}

const SingleCoin: React.FC<ISingleCoin> = ({
  data: { code, rate, offerPrice, fluctuatedPercent, offerPercent },
}) => {
  const coinDetails = useMemo(() => {
    return fetchCoinDetails(code);
  }, [code]);

  const navigate = useNavigate();

  const randomClient = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * clients.length);
    return clients[randomIndex];
  }, [offerPrice]);

  return (
    <Box
      className={classes.row}
      style={{ cursor: "pointer" }}
      onClick={() =>
        checkInsufficientBalance(offerPrice, navigate, () =>
          navigate(`/buy-token/${code.toLowerCase()}`)
        )
      }
    >
      <div className={classes.cryptoIcon}>{coinDetails.icon}</div>
      <div className={classes.content}>
        <div className={classes.title}>
          <p> {code} </p>
          <span> {coinDetails.name} </span>
        </div>
        <div className={classes.rateChange}>
          <div className={`${classes.offPrice} ${classes.offerPriceBox}`}>
            <Flex align={"center"} justify={"end"} className={classes.offerTag}>
              <span className={classes.changesValue}>
                {fluctuatedPercent?.toFixed(2) ?? offerPercent}%
              </span>
            </Flex>
          </div>
          <div className={`${classes.offPrice} ${classes.headerCol}`}>
            <Flex
              className={classes.priceOfferBox}
              align={"center"}
              justify={"center"}
              w={"100%"}
            >
              <div className={`${classes.offPrice} ${classes.liveContainer}`}>
                {SVG.tether({ color: "#ffffff85", size: 10 })}
                {Number(rate).toLocaleString(undefined, {
                  maximumFractionDigits: coinDetails.code === "TRX" ? 4 : 2,
                  minimumFractionDigits: coinDetails.code === "TRX" ? 4 : 2,
                })}{" "}
              </div>
              <div className={`${classes.offPrice} ${classes.offerContainer}`}>
                {SVG.tether({ color: "#25c866", size: 11 })}
                {Number(offerPrice).toLocaleString(undefined, {
                  maximumFractionDigits: coinDetails.code === "TRX" ? 4 : 2,
                  minimumFractionDigits: coinDetails.code === "TRX" ? 4 : 2,
                })}
              </div>
            </Flex>
          </div>
          <div className={`${classes.offPrice} ${classes.headerCol}`}>
            <Flex align={"center"} justify={"center"}>
              <Image
                src={randomClient.icon}
                style={{ marginRight: "5px" }}
                w={15}
                h={15}
              />
              <Text
                c={"white"}
                fw={"bold"}
                size="xs"
                className={classes.fetchedText}
              >
                {randomClient.name}
              </Text>
            </Flex>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default memo(SingleCoin);
