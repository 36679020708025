import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../apiUrls";

const get = async (trackId: string) => {
  const response: TServerResponse = await request({
    url: apiUrls.invoice.VERIFY_PAYMENT,
    method: "GET",
    params: {
      trackId,
    },
  });
  return response;
};

export const useVerifyPaymentStatusQuery = (trackId: string) => {
  return useQuery({
    queryKey: [apiUrls.invoice.VERIFY_PAYMENT],
    queryFn: () => get(trackId),
  });
};
