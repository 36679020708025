import { memo } from 'react'
import BoardingOneScreen from '../components/splash-screen/BoardingOneScreen'

const BoardingOne = () => {
  return (
    <BoardingOneScreen/>
  )
}

export default memo(BoardingOne)
