import { Box, Flex, Text } from "@mantine/core";
import classes from "./index.module.scss";
import { IconX } from "@tabler/icons-react";
import { FC } from "react";

interface IPaymentFailed {
  title: string;
  message: string;
}
const PaymentFailed: FC<IPaymentFailed> = ({ message, title }) => {
  return (
    <Box>
      <Flex
        justify={"center"}
        align={"center"}
        direction={"column"}
        className={classes.root}
      >
        <div className={classes.success_box}>
          <div className={`${classes.icon_1} ${classes.ani3}`}>
            <span
              className={`${classes.circle_box} ${classes.error_circle_box} ${classes.lg}`}
            >
              <IconX color="white" size={50} />
            </span>
          </div>
          <div className={`${classes.icon_2} ${classes.ani5}`}>
            <span
              className={`${classes.circle_box} ${classes.error_circle_box} ${classes.md}`}
            ></span>
          </div>
          <div className={`${classes.icon_3} ${classes.ani8}`}>
            <span
              className={`${classes.circle_box} ${classes.error_circle_box}  ${classes.md}`}
            ></span>
          </div>
          <div className={`${classes.icon_4} ${classes.ani2}`}>
            <span
              className={`${classes.circle_box} ${classes.error_circle_box}  ${classes.sm}`}
            ></span>
          </div>
        </div>

        <Text className={classes.title}>{title}</Text>
        <Text className={classes.notes}>{message}</Text>
      </Flex>
    </Box>
  );
};

export default PaymentFailed;
