import { createBrowserRouter } from "react-router-dom";
import Welcome from "../pages/Welcome";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import ROUTES from "../enum/routes";
import BoardingTwo from "../pages/BoardingTwo";
import BoardingOne from "../pages/BoardingOne";
import Home from "../pages/Home";
import ProtectedRoute from "../routers/Protected.route";
import PublicRoute from "../routers/Public.route";
import Deposit from "../pages/Deposit";
import BuyCurrencyDashboard from "../pages/BuyCurrencyDashboard";
import ErrorPage from "../components/error/ErrorPage";
import BuyToken from "../pages/BuyToken";
import Profile from "../pages/Profile";
import History from "../pages/History";

import PaymentStatus from "../pages/PaymentStatus";
import PayoutStatus from "../components/payout-status/PayoutStatus";
import PaymentCheckout from "../pages/PaymentCheckout";

export const router = createBrowserRouter([
  {
    path: ROUTES.WELCOME,
    element: <PublicRoute />,
    children: [
      {
        path: ROUTES.WELCOME,
        element: <Welcome />,
      },
    ],
  },
  {
    path: ROUTES.BOARDING_ONE,
    element: <PublicRoute />,
    children: [
      {
        path: ROUTES.BOARDING_ONE,
        element: <BoardingOne />,
      },
    ],
  },
  {
    path: ROUTES.BOARDING_TWO,
    element: <PublicRoute />,
    children: [
      {
        path: ROUTES.BOARDING_TWO,
        element: <BoardingTwo />,
      },
    ],
  },
  {
    path: ROUTES.LOGIN,
    element: <PublicRoute />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />,
      },
    ],
  },
  {
    path: ROUTES.SIGN_UP,
    element: <SignUp />,
  },
  {
    path: ROUTES.HOME,
    element: <ProtectedRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: ROUTES.DEPOSIT,
        element: <Deposit />,
      },
      {
        path: ROUTES.BUY,
        element: <BuyCurrencyDashboard />,
      },
      {
        path: ROUTES.BUY_TOKEN,
        element: <BuyToken />,
      },
      {
        path: ROUTES.PROFILE,
        element: <Profile />,
      },
      {
        path: ROUTES.HISTORY,
        element: <History />,
      },
      {
        path: ROUTES.PAYMENT_SUCCESS,
        element: <PaymentStatus />,
      },
      {
        path: ROUTES.PAYOUT_STATUS,
        element: <PayoutStatus />,
      },
      {
        path: ROUTES.PAYMENT_CHECKOUT,
        element: <PaymentCheckout />,
      },
    ],
  },
]);
