import { useEffect, useMemo } from "react";
import { useWebsocket } from "../../services/socket.service";
import PaymentWaiting from "../checkout/payment-verification/PaymentWaiting";
import PaymentSuccess from "../checkout/payment-success/PaymentSuccess";
import PaymentFailed from "../checkout/payment-failed/PaymentFailed";
import { useAppSelector } from "../../redux-app/hooks";
import Container from "../../container";
import BackBtnHeader from "../backBtn-header";
import { useCheckPayoutStatusQuery } from "../../hooks/payout/query/useCheckPayoutStatus.query";
import { useParams } from "react-router-dom";

const PayoutStatus = () => {
  const { trackId } = useParams<{ trackId: string }>();
  const { isLoading, data, refetch } = useCheckPayoutStatusQuery(trackId ?? "");
  const { socket } = useWebsocket();

  const paymentInfo: TPayouts = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return undefined;
    }
  }, [isLoading, data]);

  const renderStatus = () => {
    switch (paymentInfo?.status) {
      case "Confirming":
        return (
          <PaymentWaiting
            title="Buy Request in Process"
            message="Your purchase is being processed. The cryptocurrency will be transferred to your wallet within 1-2 minutes after network confirmation.Thank you for your patience."
          />
        );
      case "Processing":
        return (
          <PaymentWaiting
            title="Buy Request in Process"
            message="Your purchase is being processed. The cryptocurrency will be transferred to your wallet within 1-2 minutes after network confirmation.Thank you for your patience."
          />
        );
      case "Complete":
        return (
          <PaymentSuccess
            title="Buy Request Completed"
            message="Your purchase has been successfully processed, and the cryptocurrency has been transferred to your wallet. Thank you for using our Discounted Alts!"
          />
        );
      case "Rejected":
        return (
          <PaymentFailed
            title="Transaction Failed"
            message="Your currency purchase could not be completed. Please check your payment details and try again. If the issue persists, contact support for assistance."
          />
        );
      default:
        return (
          <PaymentWaiting
            title="Buy Request in Process"
            message="Your purchase is being processed. The cryptocurrency will be transferred to your wallet within 1-2 minutes after network confirmation.Thank you for your patience."
          />
        );
    }
  };
  const { _id } = useAppSelector((state) => state.user.userData);
  useEffect(() => {
    if (socket) {
      socket.on("payment-status-updated", ({ userId, status }) => {
        if (_id === userId) {
          refetch();
        }
      });
    }
    return () => {
      socket?.off("payment-status-updated");
    };
  }, [socket, refetch, _id]);

  return (
    <Container loading={isLoading}>
      <BackBtnHeader heading={"Payout Status"} />
      {renderStatus()}
    </Container>
  );
};

export default PayoutStatus;
