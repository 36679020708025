import { Center } from "@mantine/core";

import React, { useEffect, useMemo } from "react";
import Container from "../../container";
import BackBtnHeader from "../backBtn-header";
import HistoryCard from "./history-card/HistoryCard";

import FooterHeader from "../footer-header/FooterHeader";
import { usePayoutHistoryQuery } from "../../hooks/payout/query/usePayoutHistory.query";
import { useWebsocket } from "../../services/socket.service";
import { useAppSelector } from "../../redux-app/hooks";

const HistoryScreen = () => {
  const { isLoading, data, refetch } = usePayoutHistoryQuery();
  const { socket } = useWebsocket();
  const { _id } = useAppSelector((state) => state.user.userData);

  const history: TPayouts[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (socket) {
      socket.on("payout-history-updated", ({ userId }) => {
        if (_id === userId) {
          refetch();
        }
      });
    }
    return () => {
      socket?.off("payout-history-updated");
    };
  }, [refetch, socket, _id]);

  return (
    <Container loading={isLoading}>
      <BackBtnHeader heading="History" />

      {history.length === 0 && (
        <Center mih={"80vh"}>
          <h4>No history found</h4>
        </Center>
      )}
      {history.map((request) => (
        <HistoryCard key={request._id} data={request} />
      ))}
      <FooterHeader />
    </Container>
  );
};

export default HistoryScreen;
