import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../apiUrls";
import { forms } from "../../../forms";

const create = async (
  data: typeof forms.buyToken.values & {
    currency: string;
    discountedAltPrice: number;
    livePrice: number;
  }
) => {
  const response: TServerResponse = await request({
    url: apiUrls.payout.CREATE_PAYOUT,
    method: "POST",
    data,
  });
  return response;
};

export const useCreatePayoutMutation = () => {
  return useMutation({ mutationFn: create });
};
