import { useEffect } from "react";
import { useWebsocket } from "../services/socket.service";
import { useAppDispatch, useAppSelector } from "../redux-app/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useCheckAuthenticated } from "../hooks/auth/useCheckAuthenthicated";

const WalletUpdated = () => {
  const { socket } = useWebsocket();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { refetch } = useCheckAuthenticated(false);

  const { _id } = useAppSelector((state) => state.user.userData);
  useEffect(() => {
    if (socket) {
      socket.on("wallet-updated", (userId: string) => {
        if (_id === userId) {
          refetch();
        }
      });
    }
    return () => {
      socket?.off("wallet-updated");
    };
  }, [socket, dispatch, queryClient, _id, refetch]);

  return null;
};

export default WalletUpdated;
