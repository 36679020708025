import { memo } from "react";
import classes from "./index.module.scss";
import { SVG } from "../../../assets/svg";
import { NavLink } from "react-router-dom";
import ROUTES from "../../../enum/routes";
import { Box, Flex } from "@mantine/core";
import { useAppSelector } from "../../../redux-app/hooks";

const WalletCard = () => {
  const { usdtWallet } = useAppSelector((state) => state.user.userData);
  return (
    <div className={classes.root}>
      <Flex justify={"space-between"} align={"center"}>
        <Box>
          <h5 className={classes.heading}>My Wallet</h5>
          <Flex align={"center"}>
            <h1 className={classes.amountText}>
              {usdtWallet.totalAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              USDT
            </h1>
          </Flex>
        </Box>
        <NavLink to={ROUTES.DEPOSIT} className={classes.card}>
          <div className={classes.svgContainer}>{SVG.deposit}</div>
          <span className={classes.smallText}>Deposit</span>
        </NavLink>
      </Flex>
    </div>
  );
};

export default memo(WalletCard);
