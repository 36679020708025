import * as yup from "yup";

export const buyTokenValidation = (
  minimumAmount: number,
  maximumAmount: number
) =>
  yup.object({
    amount: yup
      .number()
      .typeError("Enter a valid buy amount")
      .moreThan(minimumAmount, `Amount must be greater than ${minimumAmount}.`)
      .required("Enter a buy amount"),
    amountInUsdt: yup
      .number()
      .typeError("Enter a valid buy amount")
      .required("Enter a buy amount")
      .max(
        maximumAmount,
        `Insufficient amount entered. Wallet Balance: ${maximumAmount.toFixed(
          2
        )}`
      ),
    network: yup.string().required("Network type field is required"),
    memo: yup.string().when("network", (network, schema) => {
      if (network[0] === "ton")
        return schema.required("Memo field is required");
      return schema;
    }),
    address: yup.string().required("Blockchain address field is required"),
  });
