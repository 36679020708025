import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../apiUrls";
import { forms } from "../../../forms";

const create = async (data: typeof forms.deposit.values) => {
  const response: TServerResponse = await request({
    url: apiUrls.invoice.CREATE_INVOICE,
    method: "POST",
    data,
  });
  return response;
};

export const useInitiatePaymentMutation = () => {
  return useMutation({ mutationFn: create });
};
