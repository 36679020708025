import React from 'react'
import HistoryScreen from '../components/history/HistoryScreen'

const History = () => {
  return (
    <div>
      <HistoryScreen/>
    </div>
  )
}

export default History
