import { memo } from "react";
import classes from "./index.module.scss";
import { IMAGES } from "../../assets/images";

const PreLoader = () => {
  return (
    <div className={`${classes.preload} ${classes.preload_container}`}>
      <div className={classes.preload_logo}>
        <img className={classes.logo} src={IMAGES.logo} alt="" />
      </div>
    </div>
  );
};

export default memo(PreLoader);
